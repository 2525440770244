import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import HeroHeader from "../components/HeroHeader/HeroHeader";
import styles from "../assets/css/Home.module.css";

export default function Products() {
    return (
        <>
            <Helmet>
                <title>Leryon</title>
            </Helmet>
            <Navbar />
            <div>
                <div>
                    <div className={styles.heroHeader}>
                        <div className={styles.textContainer}>
                            <div className={styles.textContainerText}>
                                <h1 className={styles.text}>Our Products</h1>
                            </div>
                        </div>
                        <div className={styles.background} style={{background: 'url("/Unbenannt-1.png") center center no-repeat', backgroundSize: 'cover'}}></div>
                        <div className={styles.gradient}></div>
                    </div>
                    <div>
                        <h1>Our Products</h1>
                        <img src={'/brandmark_leryonmedia_white.png'}/>
                        <img src={'/LeryonOne.png'}/>
                        <img src={'/brandmark-200x30-white.png'} />
                    </div>
                </div>
            </div>
        </>
    )
}