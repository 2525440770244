import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import HeroHeader from "../components/HeroHeader/HeroHeader";
import styles from '../assets/css/Home.module.css';
import Footer from "../components/Footer/Footer";
import TopNavbar from "../components/Navbar/TopNavbar";
import CampsNavbar from "../components/Navbar/CampsNavbar";

export default function Camp() {
    return (
        <>
            <Helmet>
                <title>CampCurse</title>
            </Helmet>
            <TopNavbar />
            <CampsNavbar />
            <div>
                {/*<HeroHeader />*/}
                <div className={styles.heroHeader}>
                    <div className={styles.textContainer}>
                        <div className={styles.textContainerText}>
                            <h1 className={styles.text}>CampCurse - Made your Campsite</h1>
                        </div>
                    </div>
                    <div className={styles.background}></div>
                    <div className={styles.gradient}></div>
                </div>
                <div>
                    <h1>Our Products</h1>
                    <img src={'/brandmark_leryonmedia_white.png'}/>
                </div>
            </div>
            <Footer />
        </>
    )
}