import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import HeroHeader from "../components/HeroHeader/HeroHeader";
import styles from '../assets/css/Home.module.css';
import Footer from "../components/Footer/Footer";
import Tetris from "react-tetris";

export default function NotFound() {
    return (
        <div className={styles.overflowHidden}>
            <Helmet>
                <title>Leryon Corporation</title>
            </Helmet>
            <Navbar />
            <div>
                {/*<HeroHeader />*/}
                <div className={styles.heroHeader}>
                    <Tetris
                        keyboardControls={{
                            // Default values shown here. These will be used if no
                            // `keyboardControls` prop is provided.
                            down: 'MOVE_DOWN',
                            left: 'MOVE_LEFT',
                            right: 'MOVE_RIGHT',
                            space: 'HARD_DROP',
                            z: 'FLIP_COUNTERCLOCKWISE',
                            x: 'FLIP_CLOCKWISE',
                            up: 'FLIP_CLOCKWISE',
                            p: 'TOGGLE_PAUSE',
                            c: 'HOLD',
                            shift: 'HOLD'
                        }}
                    >
                        {({
                              HeldPiece,
                              Gameboard,
                              PieceQueue,
                              points,
                              linesCleared,
                              state,
                              controller
                          }) => (
                            <div>
                                <HeldPiece />
                                <div>
                                    <p>Points: {points}</p>
                                    <p>Lines Cleared: {linesCleared}</p>
                                </div>
                                <Gameboard />
                                <PieceQueue />
                                {state === 'LOST' && (
                                    <div>
                                        <h2>Game Over</h2>
                                        <button onClick={controller.restart}>New game</button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Tetris>
                </div>
                <div>
                </div>
            </div>
            <Footer />
        </div>
    )
}