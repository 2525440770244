import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import HeroHeader from "../components/HeroHeader/HeroHeader";
import styles from '../assets/css/Home.module.css';
import Footer from "../components/Footer/Footer";
import TopNavbar from "../components/Navbar/TopNavbar";
import EventsNavbar from "../components/Navbar/EventsNavbar";

export default function Events() {
    return (
        <>
            <Helmet>
                <title>Leryon Events</title>
            </Helmet>
            <TopNavbar />
            <EventsNavbar />
            <div>
                {/*<HeroHeader />*/}
                <div className={styles.heroHeader}>
                    <div className={styles.textContainerCenter}>
                        <div className={[styles.textContainerText, styles.center].join(" ")}>
                            <h1 className={[styles.text, styles.center].join(" ")}>Welcome to the Leryon Events</h1>
                            <p className={[styles.smalltext, styles.center].join(" ")}>Experience an unforgettable event on our CampCurse Minecraft server from 2/15 to 4/1/2023.</p>
                        </div>
                    </div>
                    <div className={styles.background} style={{background: 'url("https://www.peta.org/wp-content/uploads/2014/11/i6pviZK.jpg") center center no-repeat', backgroundSize: 'cover'}}></div>
                    <div className={styles.gradient}></div>
                </div>
            </div>
            <Footer />
        </>
    )
}