import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import styles from './Footer.module.css'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.copyrightContainer}>
                <p className={styles.copyrightText}>© 2023 Leryon Inc.</p>
            </div>
        </footer>
    );
};

export default Footer