import './App.css';
import React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Home from "./view/Home";
import styles from './assets/css/Root.module.css'
import Products from "./view/Products";
import Companies from "./view/Companies";
import CompaniesList from "./view/CompaniesList";
import NotFound from "./view/404";
import MediaServices, {MediaProjects} from "./view/MediaServices";
import Events from "./view/Events";
import Camp from "./view/Camp";

function App() {
    const location = useLocation();
    return (
        <div className={styles.container}>
            <Routes location={location} key={location.pathname}>
                <Route path={'/media'} element={<Navigate replace to={'/media/'} />} />
                <Route path={'/'} element={<Home />} />
                <Route path={'/products'} element={<Products />} />
                <Route path={'/companies'} element={<CompaniesList />} />
                <Route path={'/media/'} element={<Companies />} />
                <Route path={'/media/services'} element={<MediaServices />} />
                <Route path={'/media/projects'} element={<MediaProjects />} />
                <Route path={'/media/contact'} element={<MediaProjects />} />
                <Route path={'/companies/:id/:page'} element={<Companies />} />
                <Route path={'/events'} element={<Events />} />
                <Route path={'/campcurse'} element={<Camp />} />
                <Route path={'*'} element={<NotFound />} />
            </Routes>
        </div>
    );
}

export default App;
