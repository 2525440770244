import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import HeroHeader from "../components/HeroHeader/HeroHeader";
import styles from '../assets/css/Home.module.css';
import Footer from "../components/Footer/Footer";

export default function CompaniesList() {
    return (
        <>
            <Helmet>
                <title>Leryon Corporation</title>
            </Helmet>
            <Navbar />
            <div>
                {/*<HeroHeader />*/}
                <div className={styles.heroHeader}>
                    <div className={styles.textContainer}>
                        <div className={styles.textContainerText}>
                            <h1 className={styles.text}>Our Brands</h1>
                        </div>
                    </div>
                    <div className={styles.background}></div>
                    <div className={styles.gradient}></div>
                </div>
                <div>
                    <h1>Our Products</h1>
                </div>
            </div>
            <Footer />
        </>
    )
}