import React, {useEffect, useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import styles from './Navbar.module.css'

const CampsNavbar = () => {
    const [showNavbar, setShowNavbar] = useState(true);
    const [previousScrollPos, setPreviousScrollPos] = useState(0);
    const Navbar = React.useRef(null);

    useEffect(() => {
        const currentScrollPos = window.pageYOffset;
        if(currentScrollPos >= 10 && previousScrollPos === 0) {
            //Add class to navbar
            Navbar.current.classList.add(styles.customnavbarHiddenWithOutTransition);
            setShowNavbar(false);
        }
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (previousScrollPos > currentScrollPos) {
                setShowNavbar(true);
            } else {
                setShowNavbar(false);
            }
            setPreviousScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [previousScrollPos]);
    return (
        <nav ref={Navbar} className={[styles.customnavbar, styles.blueNavbar, `${showNavbar ? styles.navbarVisible : styles.customnavbarHidden}`].join(' ')}>
            <div className={styles.flexcenter}>
                <Link to={'/campcurse/'} className={[styles.brandmark, styles.brandmarkWidth].join(' ')}>
                    <img src={'/brandmark-200x30-white.png'}/>
                </Link>
            </div>
            <ul className={styles.flexcenter}>
                <li>
                    <NavLink exact className={({ isActive }) =>
                        isActive ? styles.active : undefined
                    } to="/campcurse/">Home</NavLink>
                </li>
                <li>
                    <a href="https://campcurse.com/" target="_blank" rel="noopener noreferrer">Go to CampCurse</a>
                </li>
            </ul>
        </nav>
    )
}

export default CampsNavbar