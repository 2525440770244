import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import HeroHeader from "../components/HeroHeader/HeroHeader";
import styles from '../assets/css/Home.module.css';
import projectsstyles from '../assets/css/ProjectsList.module.css';
import Footer from "../components/Footer/Footer";
import {Link, useParams} from "react-router-dom";
import TopNavbar from "../components/Navbar/TopNavbar";
import MediaNavbar from "../components/Navbar/MediaNavbar";

export default function MediaServices() {
    const Params = useParams();
    let brand = '';
    if (Params.id === 'media') {
        brand = 'Leryon MEDIA';
    }
    return (
        <>
            <Helmet>
                <title>Leryon Media</title>
            </Helmet>
            <TopNavbar />
            <MediaNavbar />
            <div>
                {/*<HeroHeader />*/}
                <div className={styles.heroHeader}>
                    <div className={styles.textContainer}>
                        <div className={styles.textContainerText}>
                            <h1 className={styles.text}>Our Services</h1>
                        </div>
                    </div>
                    <div className={styles.background} style={{background: 'url("https://cdn.pixabay.com/photo/2016/11/19/14/00/code-1839406_960_720.jpg") center center no-repeat', backgroundSize: 'cover'}}></div>
                    <div className={styles.gradient}></div>
                </div>
                <div>
                    <h1>Our Services</h1>
                    <li>
                        Flutter App Development (Android & iOS, Desktop, Web)
                    </li>
                    <li>
                        React App Development (Web)
                    </li>
                    <li>
                        Shopify App Development (Liquid)
                    </li>
                    <li>
                        Shopify Theme Development (Liquid)
                    </li>
                    <li>
                        PHP Development (Laravel, WordPress, CodeIgniter)
                    </li>
                    <li>
                        Python Development (Django, Flask)
                    </li>
                    <li>
                        Your Portfolio (Web)
                    </li>
                    <li>
                        Company Website (Web)
                    </li>
                    <li>
                        SEO Optimization (Web)
                    </li>
                    <li>
                        UX/UI Design (Android, iOS, Web, Desktop)
                    </li>
                    <li>
                        Mobile Website Optimization (Web)
                    </li>
                    <li>
                        Web Application Development (Web)
                    </li>
                </div>
            </div>
            <Footer />
        </>
    )
}

const projects = [
    {
        cover: 'https://cdn.pixabay.com/photo/2016/11/19/14/00/code-1839406_960_720.jpg',
        title: 'Leryon.com',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam varius leo et semper laoreet.',
        url: '#',
        tags: ['React', 'Next.js', 'TailwindCSS']
    },
    {
        cover: 'https://cdn.pixabay.com/photo/2016/11/19/14/00/code-1839406_960_720.jpg',
        title: 'Buy2EKM',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam varius leo et semper laoreet.',
        url: '#',
        tags: ['React', 'Next.js', 'TailwindCSS']
    },
    {
        cover: 'https://cdn.pixabay.com/photo/2016/11/19/14/00/code-1839406_960_720.jpg',
        title: 'kaimdt.com',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam varius leo et semper laoreet.',
        url: '#',
        tags: ['React', 'Next.js', 'TailwindCSS']
    }
];
export function MediaProjects() {
    const Params = useParams();
    let brand = '';
    if (Params.id === 'media') {
        brand = 'Leryon MEDIA';
    }

    const ProjectList = ({ projects }) => {
        return (
            <section className={projectsstyles.container}>
                <h2 className={projectsstyles.title}>Our Projects</h2>
                <ul className={projectsstyles.list}>
                    {projects.map((project, index) => (
                        <li key={index} className={projectsstyles.listItem}>
                            <img src={project.cover} alt={project.title} className={projectsstyles.listItemCover} />
                            <div className={projectsstyles.listItemContent}>
                                <div className={projectsstyles.listItemText}>
                                    <Link to={""} className={projectsstyles.listItemTitle}>{project.title}</Link>
                                    <p className={projectsstyles.listItemDescription}>{project.description}</p>
                                </div>
                                <div>
                                    <a href={project.url} target="_blank" rel="noopener noreferrer" className={projectsstyles.listItemLink}>View project</a>
                                    <ul className={projectsstyles.listItemTagsContainer}>
                                        {project.tags.map((tag, index) => (
                                            <li key={index} className={projectsstyles.listItemTag}>{tag}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </section>
        );
    };

    return (
        <>
            <Helmet>
                <title>Leryon Media</title>
            </Helmet>
            <TopNavbar />
            <MediaNavbar />
            <div>
                {/*<HeroHeader />*/}
                <div className={styles.heroHeader}>
                    <div className={styles.textContainer}>
                        <div className={styles.textContainerText}>
                            <h1 className={styles.text}>Our Projects</h1>
                        </div>
                    </div>
                    <div className={styles.background} style={{background: 'url("https://cdn.pixabay.com/photo/2016/11/19/14/00/code-1839406_960_720.jpg") center center no-repeat', backgroundSize: 'cover'}}></div>
                    <div className={styles.gradient}></div>
                </div>
                <div>
                    <ProjectList projects={projects} />
                    <ul>
                        <li>
                            Leryon.com (This website)
                        </li>
                        <li>
                            ElevateBoutique (Shopify Store)
                        </li>
                        <li>
                            kaimdt.com (React App)
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    )
}