import React, {useEffect, useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import styles from './Navbar.module.css'

const MediaNavbar = () => {
    const [showNavbar, setShowNavbar] = useState(true);
    const [previousScrollPos, setPreviousScrollPos] = useState(0);
    const Navbar = React.useRef(null);

    useEffect(() => {
        const currentScrollPos = window.pageYOffset;
        if(currentScrollPos >= 10 && previousScrollPos === 0) {
            //Add class to navbar
            Navbar.current.classList.add(styles.customnavbarHiddenWithOutTransition);
            setShowNavbar(false);
        }
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (previousScrollPos > currentScrollPos) {
                setShowNavbar(true);
            } else {
                setShowNavbar(false);
            }
            setPreviousScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [previousScrollPos]);
    return (
        <nav ref={Navbar} className={[styles.customnavbar, `${showNavbar ? styles.navbarVisible : styles.customnavbarHidden}`].join(' ')}>
            <div className={styles.flexcenter}>
                <Link to={'/media/'} className={styles.brandmark}>
                    <img src={'/brandmark_leryonmedia_white.png'}/>
                </Link>
            </div>
            <ul className={styles.flexcenter}>
                <li>
                    <NavLink exact className={({ isActive }) =>
                        isActive ? styles.active : undefined
                    } to="/media/">Home</NavLink>
                </li>
                <li>
                    <NavLink exact className={({ isActive }) =>
                        isActive ? styles.active : undefined
                    } to="/media/projects">Projects</NavLink>
                </li>
                <li>
                    <NavLink exact className={({ isActive }) =>
                        isActive ? styles.active : undefined
                    } to="/media/services">Services</NavLink>
                </li>
                <li>
                    <NavLink exact className={({ isActive }) =>
                        isActive ? styles.active : undefined
                    } to="/media/portfolio">Portfolio</NavLink>
                </li>
            </ul>
            <div>
                <Link to={'/media/contact'} className={styles.buttonYellow}>
                    Request a Quote
                </Link>
            </div>
        </nav>
    )
}

export default MediaNavbar