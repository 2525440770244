import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import HeroHeader from "../components/HeroHeader/HeroHeader";
import styles from '../assets/css/Home.module.css';
import Footer from "../components/Footer/Footer";
import {Link, useParams} from "react-router-dom";
import TopNavbar from "../components/Navbar/TopNavbar";
import MediaNavbar from "../components/Navbar/MediaNavbar";

export default function Companies() {
    return (
        <>
            <Helmet>
                <title>Leryon Media</title>
            </Helmet>
            <TopNavbar />
            <MediaNavbar />
            <div>
                {/*<HeroHeader />*/}
                <div className={styles.heroHeader}>
                    <div className={styles.textContainer}>
                        <div className={styles.textContainerText}>
                            <h1 className={styles.text}>Leryon Media – Creating digital solutions for a global market</h1>
                        </div>
                    </div>
                    <div className={styles.background} style={{background: 'url("https://cdn.pixabay.com/photo/2016/11/19/14/00/code-1839406_960_720.jpg") center center no-repeat', backgroundSize: 'cover'}}></div>
                    <div className={styles.gradient}></div>
                </div>
                <div>
                    <h1>Our Products</h1>
                    <div style={{height: '500px', display: 'block'}}>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}